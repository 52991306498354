export const data = [
  {
    logo: require("../assets/images/logos/capitalone.png"),
    name: "capitalOne",
  },
  {
    logo: require("../assets/images/logos/skillojo.webp"),
    name: "skillojo",
  },
  {
    logo: require("../assets/images/logos/ecdigital.png"),
    name: "ecdigital",
  },
  {
    logo: require("../assets/images/logos/rhino.png"),
    name: "rhinopay",
  },
//   {
//     logo: require("../assets/images/logos/medclick.jpeg"),
//     name: "medclick",
//   },
  {
    logo: require("../assets/images/logos/travancore.jpeg"),
    name: "travancore",
  },
  
  {
    logo: require("../assets/images/logos/digisuvidha.png"),
    name: "digisuvidha",
  },
  {
    logo: require("../assets/images/logos/phyction.png"),
    name: "phyction",
  },
  {
    logo: require("../assets/images/logos/appointy.png"),
    name: "travancore",
  },
];
